































import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";

@Component({
  name: "Error404"
})
export default class Error404 extends Vue {}
